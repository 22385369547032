import { component } from 'picoapp'
import choozy from 'choozy'
import { qs, qsa, on, remove, add, toggle } from '@selfaware/martha'
import flickity from 'flickity'

export default component((node, ctx) => {
  let { content, projectCard, videoSlot, slotTitle, videoWrap, closeVideo, loader } =
    choozy(node)

  const offLoad = videoSlot.addEventListener('loadeddata', (e) => {
    if (videoSlot.readyState >= 3) {
      add(loader, 'hidden')
      remove(videoWrap, 'opacity-0')
    }
  })

  const offOpen = on(projectCard, 'click', (e) => {
    let el = e.target
    let src = el.dataset.video
    let title = el.dataset.title

    remove(loader, 'hidden')
    add(videoWrap, 'opacity-0')

    videoSlot.setAttribute('src', src)
    slotTitle.innerHTML = title
    remove(content, 'pointer-events-none')
    remove(content, 'opacity-0')
  })

  const offClose = on(closeVideo, 'click', () => {
    videoSlot.pause()
    add(content, 'pointer-events-none')
    add(content, 'opacity-0')
  })

  return () => {
    offOpen
    offClose
    offLoad
  }
})
