import { component } from 'picoapp'
import choozy from 'choozy'
import { qs, qsa, on, remove, add, toggle } from '@selfaware/martha'
import { gsap } from 'gsap'

export default component((node, ctx) => {
  let marqueeLength
  let ww = document.documentElement.clientWidth
  const tl = gsap.timeline()

  const initMarquee = () => {
    marqueeLength = node.scrollWidth / 2 + 80

    if (node.dataset.direction == 'left') {
      tl.fromTo(
        node,
        { x: 0 },
        {
          x: `-${marqueeLength - 40}px`,
          duration: ww > 850 ? 25 : 15,
          ease: 'linear',
          onComplete: () => initMarquee(),
        },
      )
    } else {
      tl.fromTo(
        node,
        { x: `-${marqueeLength - 40}px` },
        { x: 0, duration: ww > 850 ? 25 : 15, ease: 'linear', onComplete: () => initMarquee() },
      )
    }
  }

  setTimeout(() => {
    initMarquee()
  }, 1000);

  return () => {
  }
})
