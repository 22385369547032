import { component } from 'picoapp'
import { noop, on, remove, qs } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let offLoad = noop
  let offEnd = noop

  ctx.on('enter:completed', () => {
    let img = qs('.js-img', node)
    let lqip = qs('.js-lqip', node)

    offLoad = on(img, 'load', () => {
      offLoad()
      offLoad = noop

      if (lqip) {
        offEnd = on(img, 'transitionend', () => {
          offEnd()
          offEnd = noop
          lqip.remove()
        })
      }

      remove(img, 'opacity-0')
    })

    //check if url has filters
    url = new URL(window.location)
    searchParams = url.searchParams
    let existingFilters = searchParams.getAll('filter')

    if (existingFilters.length < 1) {
      img.src = img.dataset.src
      img.removeAttribute('data-src')
    }

  })

  return () => {
    offLoad()
    offEnd()
  }
})
