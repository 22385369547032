import { component } from 'picoapp'
import choozy from 'choozy'
import { qs, qsa, on, remove, add, toggle } from '@selfaware/martha'
import flickity from 'flickity'

export default component((node, ctx) => {
  let { carousel, prev, next, counter } = choozy(node)
  let cw = 0
  let offPrev
  let offNext
  let offChange
  let ww = document.documentElement.clientWidth

  const initFlkty = () => {
    ww = document.documentElement.clientWidth

    const options = {
      cellAlign: 'left',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      lazyLoad: true,
      draggable: ww > 850 ? false : true,
      imagesLoaded: true,
    }

    const flkty = new flickity(carousel, options)

    setTimeout(() => {
      flkty.resize()
    }, 500)

    offPrev = on(prev, 'click', () => {
      flkty.previous()
    })

    offNext = on(next, 'click', () => {
      flkty.next()
    })

    offChange = flkty.on('change', (index) => {
      counter.innerHTML = index + 1
    })
  }

  const checkSlides = () => {
    let slides = qsa('.slide', node)

    slides.forEach((slide) => {
      let sw = slide.offsetWidth
      cw = cw + sw
    })

    if (cw > carousel.offsetWidth) {
      remove(carousel, 'flex')
      remove(qs('.carousel-nav', node), 'hidden')
      initFlkty()
    } else {
      add(carousel, 'flex')
      add(qs('.carousel-nav', node), 'hidden')
    }
  }

  checkSlides()

  return () => {
    offPrev
    offNext
    offChange
  }
})
