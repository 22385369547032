import { picoapp } from 'picoapp'
import { size, qs } from 'martha'

import lazy from './components/lazy'
import scroll from './components/scroll'
import sticky from './components/sticky'
import videoHover from './components/videoHover'
import project from './components/project'
import carousel from './components/carousel'
import marquee from './components/marquee'
import filter from './components/filter'
import home from './components/home'
import menu from './components/menu'
import talent from './components/talent'
import signup from './components/signup'

const components = { lazy, scroll, sticky, videoHover, project, carousel, marquee, filter, home, menu, talent, signup }

const sizes = size()
const state = {
  ...sizes,
  mx: sizes.ww / 2,
  my: sizes.wh / 2,
  dom: {
    html: document.documentElement,
    body: document.body,
    scrollProxy: qs('.js-scroll-proxy'),
  },
  fonts: [
    // { family: 'GT Walsheim' },
    // { family: 'GT Walsheim', options: { weight: 300 } },
    // { family: 'GT Walsheim', options: { weight: 300, style: 'italic' } },
  ],
}

export default picoapp(components, state)
