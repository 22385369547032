import { remove, qsa, qs, add } from 'martha'

export default function activePage() {
  let curUrl = window.location.pathname
  let dots = qsa('.header-dot')
  let logos = qsa('.header-logo')

  qsa('.js-headerLink').forEach((link) => {
    if (link.getAttribute('href') == curUrl) {
      add(link, 'active')
    } else {
      remove(link, 'active')
    }
  })

  if (curUrl == '/') {
    dots.forEach((dot) => remove(dot, 'opacity-0'))
    logos.forEach((logo) => add(logo, 'opacity-0'))
  } else {
    dots.forEach((dot) => add(dot, 'opacity-0'))
    logos.forEach((logo) => remove(logo, 'opacity-0'))
  }
}
