import { component } from 'picoapp'
import { noop, on, remove } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let {video, cover} = choozy(node)

  on(node, 'mouseover', () => {
    cover.style.opacity = 0
    video.play()
  })

  on(node, 'mouseout', () => {
    cover.style.opacity = 1
    video.pause()
  })

  return () => {

  }
})
