import { component } from 'picoapp'
import choozy from 'choozy'
import { on, toggle, has, add, remove } from '@selfaware/martha'
import { gsap, Expo, Power4 } from 'gsap'

export default component((node, ctx) => {
  let { toggleMenu, burger, close, menu } = choozy(node)
  let isAnimating = false
  const tl = gsap.timeline()

  const closeMenu = () => {
    remove(burger, 'hidden')
    add(close, 'hidden')
    tl.set(menu, { pointerEvents: 'none' }).to(menu, {
      opacity: 0,
      duration: 0.5,
      onComplete: () => {
        isAnimating = false
        remove(menu, 'is-open')
      },
    })
  }

  const openMenu = () => {
    add(burger, 'hidden')
    remove(close, 'hidden')
    tl.to(menu, { opacity: 1, duration: 0.5 })
      .set(menu, { pointerEvents: 'initial' })
      .fromTo(
        '.menu-item',
        { opacity: 0 },
        {
          opacity: 1,
          stagger: 0.08,
          duration: 1,
          onComplete: () => {
            isAnimating = false
            add(menu, 'is-open')
          },
        },
        '-=.1',
      )
  }

  ctx.on('menu:close', () => {
    closeMenu()
  })

  on(toggleMenu, 'click', () => {
    if (isAnimating) return
    isAnimating = true
    if (has(menu, 'is-open')) {
      closeMenu()
    } else {
      openMenu()
    }

    toggle(menu, 'is-open')
  })
})
