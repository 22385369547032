import { component } from 'picoapp'
import choozy from 'choozy'
import { qs, qsa, on, remove, add, toggle } from '@selfaware/martha'
import gsap from 'gsap'

export default component((node, ctx) => {
  let dots = qsa('.header-dot')
  let logos = qsa('.header-logo')

  dots.forEach((dot) => remove(dot, 'opacity-0'))
  logos.forEach((logo) => add(logo, 'opacity-0'))

  const offScroll = on(document, 'scroll', () => {
    let wh = document.documentElement.clientHeight

    if (window.scrollY < wh) {
      dots.forEach((dot) => remove(dot, 'opacity-0'))
      logos.forEach((logo) => add(logo, 'opacity-0'))
    } else {
      dots.forEach((dot) => add(dot, 'opacity-0'))
      logos.forEach((logo) => remove(logo, 'opacity-0'))
    }
  })

  return () => {
    offScroll()
  }
})
