import { component } from 'picoapp'
import choozy from 'choozy'
import { qs, qsa, on, remove, add, toggle, noop } from 'martha'
import flickity from 'flickity'
import { gsap, ScrollToPlugin } from 'gsap/all'

export default component((node, ctx) => {
  let {
    gallery,
    prev,
    next,
    cursor,
    cursorPrev,
    cursorNext,
    counter,
    info,
    hide,
    playVideo,
  } = choozy(node)

  let btns = qsa('.js-carouselBtn')

  let offPrev = noop
  let offNext = noop
  let offMove = noop
  let offChange = noop
  let offNextOver = noop
  let offPrevOver = noop
  let hideArrow = noop

  gsap.registerPlugin(ScrollToPlugin)

  if (prev && next) {
    const options = {
      cellAlign: 'center',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      lazyLoad: true,
      draggable: false,
      imagesLoaded: true,
      fade: false,
      selectedAttraction: 1,
      friction: 1,
    }

    const flkty = new flickity(gallery, options)

    setTimeout(() => {
      flkty.resize()
    }, 500)

    offPrev = on(prev, 'click', () => {
      flkty.previous()
    })

    offNext = on(next, 'click', () => {
      flkty.next()
    })

    offChange = flkty.on('change', (index) => {
      let curSlide = qs(`.project-slide[data-index="${index + 1}"]`, node)
      let videos = qsa('video', node)
      if (videos.length > 0) {
        videos.forEach((video) => {
          video.pause()
        })
      }

      counter.innerHTML = index + 1

      if (curSlide.dataset.type == 'video') {
        // qs('video', curSlide).play()
        remove(playVideo, 'hidden')
      } else {
        add(playVideo, 'hidden')
      }
    })

    on(playVideo, 'click', () => {
      let curSlide = qs('.project-slide.is-selected', node)
      qs('video', curSlide).play()
      add(playVideo, 'hidden')
    })

    offMove = on(document, 'mousemove', (e) => {
      e = e || window.event
      cursor.style.top = `${(e.pageY || e.clientY) - 15}px`
      cursor.style.left = `${(e.pageX || e.clientX) - 15}px`
    })

    offNextOver = on(next, 'mouseover', () => {
      remove(cursor, 'opacity-0')
      remove(cursorNext, 'hidden')
      add(cursorPrev, 'hidden')
    })

    offPrevOver = on(prev, 'mouseover', () => {
      remove(cursor, 'opacity-0')
      add(cursorNext, 'hidden')
      remove(cursorPrev, 'hidden')
    })

    hideArrow = btns.forEach((btn) => {
      on(btn, 'mouseout', () => {
        add(cursor, 'opacity-0')
        remove(document.body, 'cursor-hide')
      })
    })
  } else {
    if (playVideo) {
      on(playVideo, 'click', () => {
        let vid = qs('video', node)
        vid.play()
        add(playVideo, 'hidden')
        vid.controls = true
      })
    }
  }

  // if (has(qs('.project-slide[data-index="1"]'), 'slide-video')){
  //   qs('.project-slide[data-index="1"]').play()
  // }

  const offInfo = on(info, 'click', () => {
    if (node.dataset.type != 'curation') {
      gsap.to(window, { duration: 0.5, scrollTo: '.project-info' })
    } else {
      toggle(hide, 'hidden')
    }
  })

  return () => {
    offInfo()
    offPrev()
    offNext()
    offChange()
    offMove()
    offPrevOver()
    offNextOver()
    hideArrow()
  }
})
