import { component } from 'picoapp'
import { on, has, remove, add, qsa } from '@selfaware/martha'
import { update, bind } from 'lazim'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { filter, zero, category } = choozy(node)
  let searchParams
  let url
  let offCategory

  const clearFilters = () => {
    let filters = qsa('.js-filter')
    filters.forEach((filter) => {
      remove(filter, 'active')
      remove(filter, 'inactive')
    })
  }

  const checkFilters = (activeFilters) => {
    let filters = qsa('.js-filter')

    filters.forEach((filter) => {
      if (activeFilters.some((tag) => tag == filter.dataset.filter)) {
        add(filter, 'active')
        remove(filter, 'inactive')
      } else {
        remove(filter, 'active')
        add(filter, 'inactive')
      }
    })
  }

  const findMatch = (filters) => {
    let cards = qsa('.js-filterItem')
    cards.forEach((card) => {
      if (filters.length < 1) {
        remove(card, 'hidden')
        remove(document.body, 'is-filtered')
      } else {
        let tags = card.dataset.tags.split(' ')
        const found = tags.some((r) => filters.includes(r))
        remove(card, 'hidden')
        add(document.body, 'is-filtered')
        if (found) {
          remove(card, 'hidden')
        } else {
          add(card, 'hidden')
        }
      }
    })
    if (qsa('.js-filterItem:not(.hidden)').length < 1) {
      remove(zero, 'hidden')
    } else {
      add(zero, 'hidden')
    }

    //trigger lazy load of new images in view
    update()
    bind()
  }

  const filterOnLoad = () => {
    url = new URL(window.location)
    searchParams = url.searchParams
    let existingFilters = searchParams.getAll('filter')

    if (existingFilters.length > 0) {
      checkFilters(existingFilters)
      findMatch(existingFilters)

      qsa('.js-img').forEach(im => remove(im, 'opacity-0'))
    }
  }

  const offFilter = on(filter, 'click', (e) => {
    let curFilter = e.target.dataset.filter

    url = new URL(window.location)
    searchParams = url.searchParams

    let activeFilters = searchParams.getAll('filter')

    if (activeFilters.some((value) => value === curFilter)) {
      remove(e.target, 'active')
      add(e.target, 'inactive')
      const tags = activeFilters.filter((tag) => tag !== curFilter)
      searchParams.delete('filter')
      for (const tag of tags) searchParams.append('filter', tag)
    } else {
      add(e.target, 'active')
      remove(e.target, 'inactive')
      searchParams.append('filter', curFilter)
      url.search = searchParams.toString()
    }

    let new_url = url.toString()
    if (history.pushState) {
      window.history.pushState({ path: new_url }, '', new_url)
    }

    let newFilters = searchParams.getAll('filter')
    checkFilters(newFilters)

    if (newFilters.length < 1) {
      clearFilters()
    }
    findMatch(newFilters)
  })

  if (category) {
    offCategory = on(category, 'click', (e) => {
      let curCategory = e.target.dataset.category
      let blocks = qsa('.js-categoryContent')
      let btns = qsa('.js-category')

      if (has(e.target, 'active')) {
        blocks.forEach((block) => {
          add(block, 'hidden')
        })

        btns.forEach((btn) => {
          remove(btn, 'active')
        })
      } else {
        blocks.forEach((block) => {
          if (block.dataset.category != curCategory) {
            add(block, 'hidden')
          } else {
            remove(block, 'hidden')
          }
        })

        btns.forEach((btn) => {
          remove(btn, 'active')
        })

        add(e.target, 'active')
      }
    })
  }

  filterOnLoad()

  return () => {
    offFilter()
    remove(document.body, 'is-filtered')
    if (category) {
      offCategory()
    }
  }
})
